import React from 'react'
import styles from './RelatedItem.module.css'
import Image from '../Image/Image'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'

export default function RelatedItem({data}) {
  const {title, description, image} = data.fields

  return (
    <div className={styles.RelatedItem}>
      {image &&
      <Image
        data={image}
        className={styles.image}
        width={1024}
      />
      }

      {title &&
      <h3>{title}</h3>
      }
      {description &&
      documentToReactComponents(description)
      }
    </div>
  )
}
