import React from 'react'
import styles from './DownloadLink.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'

export default function DownloadLink({id, data}) {
  // console.log('DownloadLink', data)
  if(!data || !data) return null
  const {title, file} = data

  const extension = file?.fields?.file?.url?.substr(-3)


  return (
    <section id={id} className={styles.DownloadLink}>
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-10 col-md-6 col-xl-4">
            <div className={styles.frame}>
              {title &&
              <span className={styles.title}>{title}</span>
              }

              {file?.fields?.file?.url &&
              <a
                href={file?.fields?.file?.url}
                download
                target="_blank"
                rel="nofollow noreferrer"
                className="button secondary"
              >
                <div className={styles.iconWrapper}>
                  <Svg id={ICONS.file}/>
                  <span className={styles.extension}>{extension}</span>
                </div>
                <span>Download</span>
              </a>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
