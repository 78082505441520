import React from 'react'
import styles from './RelatedItems.module.css'
import RelatedItem from '../RelatedItem/RelatedItem'
import TitleDescription from '../TitleDescription/TitleDescription'

export default function RelatedItems({id, data}) {
  // console.log('data', data)
  const {title, description, items} = data

  return (
    <section id={id} className={styles.RelatedItems}>
      <TitleDescription data={{title, description}} className={styles.titleDescription}/>

      <div className="container">
        <div className="row">
          {items &&
          items.map((item, idx) => {
            return (
              <div key={idx} className="col-xs-12 col-sm-4">
                <RelatedItem data={item}/>
              </div>
            )
          })
          }
        </div>
      </div>
    </section>
  )
}
