import React from 'react'
import styles from './OnBoardingScreen.module.css'
import {BLOCKS} from '@contentful/rich-text-types'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Image from '../../Image/Image'

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return <Image data={node.data.target} classNameFigure={styles.figure}/>
    }
  }
}

export default function OnBoardingScreen({data}) {
  if(!data || !data.fields) return null
  const {title, description} = data.fields
  // console.log('OnBoardingScreen', data)

  return (
    <div className={styles.OnBoardingScreen}>
      {title &&
      <h3>{title}</h3>
      }
      {description &&
      documentToReactComponents(description, options)
      }
    </div>
  )
}
