import React from 'react'
import InnerHTML from 'dangerously-set-html-content'

export default function HtmlSnippet({id, data}) {
  if(!data) return null
  const {htmlSnippet} = data
  // console.log('HtmlSnippet', htmlSnippet)

  return (
    <section id={id}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-offset-1 col-lg-10 col-xxl-offset-2 col-xxl-8">
            <InnerHTML html={htmlSnippet} />
          </div>
        </div>
      </div>
    </section>
  )
}
