import React, {Component} from 'react'
import styles from './PropertiesFeatured.module.css'
import TitleDescription from '../TitleDescription/TitleDescription'
import PropertyFeatured from '../PropertyFeatured/PropertyFeatured'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import DotNavigation from '../DotNavigation/DotNavigation'
import {isDesktop} from '../../services/service-utils'
import {analyticsEvent} from '../../services/service-analytics'

class PropertiesFeatured extends Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.onDotClick = this.onDotClick.bind(this)
    this.onNavClick = this.onNavClick.bind(this)

    this.state = {
      index: 0,
      pfHeight: 0,
      direction: 1,
    }
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.onResize)

    if(isDesktop()) {
      this.autoInterval = setInterval(() => {
        this.onNavClick(1, true)
      }, 5000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoInterval)
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    const element = document.getElementById(`pf_${this.state.index}`)
    if(!element) return null
    const h = element.getBoundingClientRect().height
    this.setState({
      pfHeight: h
    })
  }

  onDotClick(index) {
    clearInterval(this.autoInterval)
    analyticsEvent('properties_featured', 'featured navigate', 'dot')

    const direction = Boolean(index > this.state.index) ? 1 : -1

    this.setState({
      direction: direction,
      index: index
    }, this.onResize)
  }

  onNavClick(direction, triggeredByInterval = false) {
    if(!triggeredByInterval) {
      clearInterval(this.autoInterval)
      analyticsEvent('properties_featured', 'featured navigate', direction > 0 ? 'right' : 'left')
    }
    const {index} = this.state
    const {properties} = this.props.data
    const numItems = properties.length

    if(direction === 1 && index === (numItems - 1)) {
      this.setState({
        direction: direction,
        index: 0
      }, this.onResize)
      return
    }

    if(direction === -1 && index === 0) {
      this.setState({
        direction: direction,
        index: numItems - 1
      }, this.onResize)
      return
    }

    this.setState(prevState => {
      return {
        direction: direction,
        index: prevState.index + direction
      }
    }, this.onResize)
  }

  render() {
    const {id, data} = this.props
    const {index, pfHeight, direction} = this.state
    const {title, description, properties} = data
    if(!properties) return null

    const numItems = properties.length

    const styleProperties = {
      height: !isDesktop() ? `${pfHeight}px` : ''
    }

    return (
      <section id={id} className={styles.PropertiesFeatured}>
        <TitleDescription data={{title, description}}/>

        <div className={styles.scrollContainer}>
          <div className={styles.properties} style={styleProperties}>
            {properties && numItems > 0 &&
            properties.map(p => p.fields).map((property, idx) => (
              <PropertyFeatured
                key={idx}
                data={property}
                idx={idx}
                index={index}
                isActive={idx === index}
                numItems={numItems}
                show={idx === index}
                direction={direction}
              />
            ))
            }
          </div>

          <DotNavigation
            numItems={numItems}
            index={index}
            setIndex={this.onDotClick}
            className={styles.dots}
          />

          <div className={styles.navigation}>
            <button
              onClick={() => this.onNavClick(-1)}
              className={styles.navButton}
            >
              <Svg id={ICONS.navLeftSmall}/>
            </button>
            <button
              onClick={() => this.onNavClick(1)}
              className={styles.navButton}
            >
              <Svg id={ICONS.navRightSmall}/>
            </button>
          </div>
        </div>
      </section>
    )
  }
}

export default PropertiesFeatured
