import React from 'react'
import styles from './Platforms.module.css'
import TitleDescription from '../TitleDescription/TitleDescription'
import PlatformFeatured from '../PlatformFeatured/PlatformFeatured'

export default function Platforms({id, data}) {
  const {title, description, platforms} = data

  return (
    <section id={id} className={styles.Platforms}>
      <TitleDescription data={{title, description}}/>

      {platforms && platforms.length > 0 &&
      <div className="container">
        <div className="row">
          {
            platforms.map(p => p.fields).map((platform, idx) => {
              return (
                <div key={idx} className="col-xs-12 col-sm-6 col-md-4">
                  <PlatformFeatured data={platform}/>
                </div>
              )
            })
          }
        </div>
      </div>
      }
    </section>
  )
}
