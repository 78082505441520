import React, {Component} from 'react'
import styles from './PropertiesMapFilters.module.css'
import {fetchEntries} from '../../services/service-contentful'
import PropertiesMapFilterList from '../PropertiesMapFilterList/PropertiesMapFilterList'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {isDesktop} from '../../services/service-utils'
import {analyticsEvent} from '../../services/service-analytics'

class PropertiesMapFilters extends Component {
  constructor(props) {
    super(props)

    this.onToggleFilters = this.onToggleFilters.bind(this)

    this.state = {
      show: isDesktop(),
      countries: null,
      funds: null,
      platforms: null,
      segments: null,
      statuses: null,
      tours: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    const countries = await fetchEntries('country')
    const funds = await fetchEntries('fund')
    const platforms = await fetchEntries('platform')
    const segments = await fetchEntries('segment')
    const statuses = await fetchEntries('status')

    this.setState({
      countries: countries.map(d => d.fields.title).sort(),
      funds: funds.map(d => d.fields.title).sort().reverse(),
      platforms: platforms.map(d => d.fields.title).sort().filter(t => t !== 'Other').concat('Other'),
      segments: segments.map(d => d.fields.title).sort().filter(t => t !== 'Other').concat('Other'),
      statuses: statuses.map(d => d.fields.title).sort(),
      tours: ['Tour available'],
    })
  }

  onToggleFilters() {
    this.setState(prevState => {
      return {
        show: !prevState.show
      }
    }, () => {
      analyticsEvent('properties_map', 'map toggle filters', this.state.show ? 'open' : 'close')
    })
  }

  render() {
    const {
      propertiesFiltered,
      onFilterChange,
      onResetFilters,
      filtersAll,
    } = this.props

    // console.log('filtersAll', filtersAll)

    const numFilters = filtersAll.map(f => f.filters).flat().length
    // console.log('numFilters', numFilters)

    const {
      show,
      countries,
      funds,
      platforms,
      segments,
      statuses,
      tours,
    } = this.state

    const showButtonReset = numFilters !== 0

    return (
      <>
        <button
          className={show ? styles.buttonFiltersOpen : styles.buttonFilter}
          onClick={this.onToggleFilters}
        >
          <span className={styles.buttonBg}/>
          <span>
            <span>Filters</span>
            <span className={styles.numFilters}>{numFilters > 0 ? `(${numFilters})` : ''}</span>
          </span>
          <div className={styles.iconBg}>
            <Svg id={ICONS.plus}/>
          </div>
        </button>

        <button
          className={`button link ${showButtonReset && show ? styles.buttonResetShow : styles.buttonReset}`}
          onClick={onResetFilters}
        >
          Clear all
        </button>

        <div className={show ? styles.show : styles.PropertiesMapFilters}>
          <div className={styles.scrollContainer}>
            <div className={styles.content}>
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={tours}
                field="tour"
                filterId="filtersTours"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={countries}
                field="country"
                filterId="filtersCountries"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={funds}
                field="fund"
                filterId="filtersFunds"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={platforms}
                field="platform"
                filterId="filtersPlatforms"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={segments}
                field="segment"
                filterId="filtersSegments"
                onFilterChange={onFilterChange}
              />
              <PropertiesMapFilterList
                propertiesFiltered={propertiesFiltered}
                filtersAll={filtersAll}
                data={statuses}
                field="status"
                filterId="filtersStatuses"
                onFilterChange={onFilterChange}
              />

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PropertiesMapFilters
