import React from 'react'
import styles from './Story.module.css'
import Image from '../Image/Image'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import DescriptionList from '../DescriptionList/DescriptionList'
import Person from '../Person/Person'
import Map from '../Map/Map'

export default function Story({id, data}) {
  if(!data) return null
  // console.log('Story', data)

  const {
    title,
    description,
    descriptionList,
    showAsBulletList,
    image,
    vimeoUrl,
    mapReference,
    mediaSide,
    person,
  } = data

  const property = mapReference ? [mapReference.fields].map(p => {
    const {
      title,
      enableTour,
      propertyId,
      image,
      status,
      country,
      fund,
      segment,
      platform,
      geoLocation,
    } = p

    return {
      id: propertyId,
      image_url: image?.fields?.file?.url,
      name: title,
      status: status?.fields?.title,
      country: country?.fields?.title,
      fund: fund?.fields?.title,
      segment: segment?.fields?.title,
      platform: platform?.fields?.title,
      latitude: geoLocation?.lat,
      longitude: geoLocation?.lon,
      tour: enableTour ? 'Tour available' : false
    }
  })[0] : null

  const mediaRight = Boolean(mediaSide === false)

  return (
    <section id={id} className={styles.Story}>
      <div className="container">
        <div className="row">
          <div className={`col-xs-12 col-sm-6 col-lg-5 col-xxl-4 ${mediaRight ? 'col-xxl-offset-0 last-sm' : 'col-lg-offset-1 col-xxl-offset-2'}`}>
            {image &&
            <Image
              data={image}
              width={1024}
              className={styles.media}
            />
            }

            {vimeoUrl &&
            <VimeoPlayer
              vimeoUrl={vimeoUrl}
              background={false}
              className={styles.media}
            />
            }

            {mapReference &&
            <Map
              propertiesFiltered={[property]}
              centerLatLng={{lat: property.latitude, lng: property.longitude}}
              zoom={5}
              enableInfo={false}
              enableFullscreen={true}
              className={`${styles.media} ${styles.map}`}
            />
            }
          </div>
          <div className={`col-xs-12 col-sm-6 col-lg-5 col-xxl-4 ${mediaRight ? 'col-lg-offset-1 col-xxl-offset-2' : 'col-xxl-offset-0'}`}>
            <div className={styles.content}>
              {title &&
              <h2>{title}</h2>
              }
              {description &&
              documentToReactComponents(description)
              }
              {descriptionList &&
              <DescriptionList data={descriptionList} showAsBulletList={showAsBulletList}/>
              }
              {person &&
              <Person data={person} mediaRight={mediaRight}/>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
