import React from 'react'
import styles from './DataList.module.css'
import DataListItem from '../DataListItem/DataListItem'
import {isDesktop} from '../../services/service-utils'

export default function DataList({id, data}) {
  if(!data || !data.items) return null
  const {items} = data
  // console.log('DataList', items)

  const numItems = items.length
  const mod = numItems % 3
  const addOns = mod > 0 && isDesktop() ? Math.abs((numItems % 3) - 3) : 0

  const itemsFilled = [...items, ...[...Array(addOns)].map(idx => {
    return {
      fields:  {}
    }
  })]

  return (
    <section id={id} className={styles.DataList}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-offset-1 col-lg-10 col-xxl-offset-2 col-xxl-8">
            <ul className={styles.list}>
              {
                itemsFilled.map((item, idx) => <DataListItem key={idx} data={item}/>)
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
