import React from 'react'
import styles from './Property.module.css'
import Platform from '../Platform/Platform'
import PropertyHeader from '../PropertyHeader/PropertyHeader'
import PropertyAttribute from '../PropertyAttribute/PropertyAttribute'
import PropertyTabs from '../PropertyTabs/PropertyTabs'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom'

export default function Property({id, data}) {
  const {url} = useRouteMatch()

  const {
    tour,
    facts,
    location,
    construction,
    profile,
    team,
    tenants,
    strategy,
  } = data

  // console.log('Property', data)

  const tabs = [
    {key: 'tour', data: tour},
    {key: 'facts', data: facts},
    {key: 'location', data: location},
    {key: 'construction', data: construction},
    {key: 'sustainability', data: profile},
    {key: 'team', data: team},
    {key: 'tenants', data: tenants},
    {key: 'strategy', data: strategy?.fields?.strategy},
  ]

  return (
    <Router>
      <div id={id} className={styles.Property}>
        <PropertyHeader data={data}/>
        <Switch>
          <Route exact path="/:slug/:tabId?">
            <PropertyTabs
              data={tabs}
              url={url}
            />
            <PropertyAttribute data={tabs}/>
          </Route>
        </Switch>

        {strategy &&
        <Platform data={strategy}/>
        }
      </div>
    </Router>
  )
}
