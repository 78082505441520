import React, {useRef} from 'react'
import styles from './PropertyTabs.module.css'
import {scrollToElement} from '../../services/service-utils'
import {analyticsEvent} from '../../services/service-analytics'
import {NavLink, useParams} from 'react-router-dom'

export default function PropertyTabs(props) {
  const element = useRef(null)
  const {slug, tabId} = useParams()
  const {data} = props
  // console.log('slug', slug)
  // console.log('tabId', tabId)

  const tab = tabId ? tabId : 'tour'

  return (
    <section ref={element} className={styles.PropertyTabs}>
      <ul className={styles.list}>
        {
          data.map((tabData, idx) => {
            const {key, data} = tabData
            if(!data) return null
            const isActive = key === tab
            return (
              <li key={idx}>
                <NavLink
                  to={`/${slug}/${key}`}
                  className={isActive ? styles.active : styles.link}
                  onClick={event => {
                    analyticsEvent('property', 'property tab click', key)
                    let target = element.current.previousSibling
                    if(!target) target = document.querySelector('nav')
                    if(!target) target = document.querySelector('header')
                    if(!target) {
                      console.log('no scrollTo target')
                      return
                    }
                    scrollToElement(target, target.getBoundingClientRect().height)
                  }}>
                  {key}
                </NavLink>
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}
