export function getUrlParams(location) {
  const {search} = location
  if(!search.includes('?')) return {}
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split('=')
    return Object.assign(params, {[key]: decodeURIComponent(val)})
  }, {})
}

export function pushUrlParams(history, params, preventPush, path, keepPageIndex = false) {
  const search = Object.keys(params)
    .filter(key => params[key])
    .map(key => {
      let value = null

      // reset page to '0', unless push comes from SearchAutoPaging
      if(key === 'page') {
        if(!keepPageIndex) {
          value = 0
        } else {
          value = params[key]
        }
      } else {
        value = encodeURIComponent(params[key])
      }

      return `${key}=${value}`
    }).join('&')

  if(preventPush) {
    return `?${search}`
  }

  const pathname = path ? path : history.location.pathname.split('?')[0]

  history.push({
    pathname: pathname,
    search: search
  })
}

export function resetPageIndex(search) {
  const pageParam = '&page='
  if(!search || !search.includes(pageParam)) return search

  const params = search.split('&')
  const newSearch = params
    .map(param => {
      const parts = param.split('=')
      const key = parts[0]
      let value = parts[1]
      if(key === 'page') {
        value = 0
      }
      return `${key}=${value}`
    }).join('&')

  return newSearch
}

export function getUrlParamFieldByKey(location, param, fieldKey, urlParams = null) {
  const currentUrlParams = urlParams || getUrlParams()
  const fields = currentUrlParams[param]

  if(!fields) return fields
  const fieldValue = fields.split('|').filter(f => f.split(':')[0] === fieldKey).map(f => f.split(':')[1])[0]
  return fieldValue ? decodeURIComponent(fieldValue) : null
}

export function pushSearchParamItem(history, location, param, key, value, preventPush, includeCurrentParams = true, pathname) {
  const currentSearchParams = includeCurrentParams ? getUrlParams() : {}
  const currentParam = currentSearchParams[param]
  const newParamKeyValuePair = value ? `${key}:${encodeURIComponent(value)}` : null
  let nextParam = null

  if(!currentParam && newParamKeyValuePair) {
    nextParam = newParamKeyValuePair
  }

  if(currentParam) {
    nextParam = currentParam.split('|')
      .filter(kvPair => {
        const k = kvPair.split(':')[0]
        if(k === key) return Boolean(newParamKeyValuePair)
        return true
      })
      .map(kvPair => {
        const k = kvPair.split(':')[0]
        if(k === key) return newParamKeyValuePair
        return kvPair
      }).join('|')

    if(value && !nextParam.includes(key)) nextParam = `${nextParam}|${newParamKeyValuePair}`
  }

  const newSearchParams = {
    ...{},
    ...currentSearchParams,
    ...{[param]: nextParam}
  }

  return pushUrlParams(history, newSearchParams, preventPush, pathname)
}

