import React, {Component} from 'react'
import styles from './Login.module.css'
import {Link} from 'react-router-dom'
import {PATTERN_EMAIL} from '../../constants/consts-patterns'
import {fetchEntries} from '../../services/service-contentful'
import {getCookie, setCookie} from '../../services/service-cookies'
import {COOKIES} from '../../constants/consts-cookies'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'

class Login extends Component {
  constructor(props) {
    super(props)

    this.onEmailChange = this.onEmailChange.bind(this)
    this.onTermsChange = this.onTermsChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      page: null,
      email: null,
      checked: false,
      valid: false,
      errorName: null,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getEmailFromUrl()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.authenticating !== this.props.authenticating && !this.props.authenticated) {
      this.input.focus()
    }
  }

  getEmailFromUrl() {
    const {search} = window.location
    const params = new URLSearchParams(search)
    const encoded = params.get('ue') || getCookie(COOKIES.nrep_pt_ue)
    // console.log('encodedFromUrl', encoded)

    if(encoded) {
      const email = window.atob(encoded)
      console.log('email', email)

      this.setState({
        email: email
      })
    }

    this.loadData()
  }

  async loadData() {
    const res = await fetchEntries('login')
    // console.log('loadData', res.fields)

    if(!this._isMounted) return

    if(res && res.fields) {
      this.setState({
        page: res.fields
      })
    }
  }

  async onEmailChange(event) {
    const isValid = this.form.checkValidity()

    this.setState({
      email: event.target.value,
      valid: isValid
    })
  }

  async onTermsChange(event) {
    const isValid = this.form.checkValidity()

    this.setState(prevState => {
      return {
        checked: !prevState.checked,
        valid: isValid
      }
    })
  }

  onSubmit(event) {
    event.preventDefault()
    const email = this.form.elements.namedItem('email').value.toLowerCase()

    const base64Email = window.btoa(email)
    setCookie(COOKIES.nrep_pt_ue, base64Email)

    const {authenticateUser} = this.props
    authenticateUser(email)
  }

  render() {
    const {
      authenticating,
      authenticated,
      unauthorized,
      expired,
      firstIsHero,
    } = this.props

    const {
      page,
      email,
      checked,
      valid,
    } = this.state

    if(!page) return null

    const {
      title,
      termsText,
      termsLinkText,
      termsLinkUrl,
      loginButtonText,
      titleExpired,
      textExpired,
      titleUnauthorized,
      textUnauthorized,
    } = page

    const {slug} = termsLinkUrl.fields

    return (
      <section className={firstIsHero ? styles.LoginHero : styles.Login}>
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
              {unauthorized &&
              <h1>{titleUnauthorized}</h1>
              }
              {expired &&
              <h1>{titleExpired}</h1>
              }
              {!authenticated && !authenticating && !expired && !unauthorized &&
              <h1>{title}</h1>
              }

              <form
                ref={n => this.form = n}
                className={styles.form}
                onSubmit={this.onSubmit}
              >
                <fieldset className={styles.fieldSet}>
                  <label htmlFor="email" className={styles.label}>Email</label>
                  <input
                    ref={n => this.input = n}
                    autoFocus={true}
                    name="email"
                    type="email"
                    value={email ? email : ''}
                    pattern={PATTERN_EMAIL}
                    autoComplete="off"
                    placeholder="Your email"
                    required
                    className={styles.inputEmail}
                    onChange={this.onEmailChange}
                  />
                </fieldset>


                <label className={`checkbox ${styles.checkBox}`}>
                  <input
                    name="terms"
                    type="checkbox"
                    checked={checked ? 'checked' : ''}
                    required
                    onChange={this.onTermsChange}
                  />
                  <span className="checklabel">{termsText} <Link to={slug} className={styles.termsLink}>{termsLinkText}</Link></span>
                  <span className="checkmark"/>
                </label>

                <button
                  className={`button blue inverted ${styles.button}`}
                  onClick={this.onSubmit}
                  disabled={!valid}
                >
                  {loginButtonText}
                </button>


                {unauthorized &&
                <div className={styles.text}>
                  <Svg id={ICONS.info}/>
                  {documentToReactComponents(textUnauthorized)}
                </div>
                }

                {expired &&
                <div className={styles.text}>
                  <Svg id={ICONS.info}/>
                  {documentToReactComponents(textExpired)}
                </div>
                }

              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Login
