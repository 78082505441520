import React from 'react'
import styles from './PlatformFeatured.module.css'
import Image from '../Image/Image'
import {analyticsEvent} from '../../services/service-analytics'

export default function PlatformFeatured({data}) {
  const {title, tagLine, logo, linkUrl} = data

  return (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className={styles.PlatformFeatured}
      onClick={() => {
        analyticsEvent('platforms_featured', 'platforms click', title)
      }}
    >
      {logo &&
      <Image
        data={logo}
        alt={title}
        className={styles.logo}
        width={768}
      />
      }
      <small className={styles.tagLine}>{tagLine}</small>
    </a>
  )
}
