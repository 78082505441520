import React from 'react'
import styles from './Person.module.css'
import Image from '../Image/Image'

export default function Person({data, mediaRight}) {
  if(!data) return null
  // console.log('Person', data)

  const {
    image,
    name,
    title,
    organisation,
    email,
    phone,
  } = data.fields

  return (
    <div className={mediaRight ? styles.PersonMediaRight : styles.Person}>
      {image &&
      <Image
        data={image}
        width={384}
        className={styles.image}
      />
      }

      <div className={styles.details}>
        {name &&
        <span className={styles.name}>{name}</span>
        }
        {title &&
        <span>{title}</span>
        }
        {organisation &&
        <span>{organisation}</span>
        }
        {email &&
        <span>@: <a href={`mailto:${email}`}>{email}</a></span>
        }
        {phone &&
        <span>P: <a href={`tel:${phone}`}>{phone}</a></span>
        }
      </div>
    </div>
  )
}
