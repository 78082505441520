import React from 'react'
import styles from './PropertyAttribute.module.css'
import ComponentMapper from '../ComponentMapper'
import {useParams} from 'react-router-dom'

export default function PropertyAttribute(props) {
  const {tabId} = useParams()
  const {data} = props
  const tab = tabId ? tabId : 'tour'
  const tabData = data.find(t => t.key === tab)
  const content = tabData?.data?.fields?.content
  if(!content) return null

  return (
    <div className={styles.PropertyAttribute}>
      {content.map((d, idx) => <ComponentMapper key={idx} data={d}/>)}
    </div>
  )
}
