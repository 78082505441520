import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import styles from './PropertiesMap.module.css'
import {StateConsumer} from '../../StateContext'
import PropertiesMapFilters from '../PropertiesMapFilters/PropertiesMapFilters'
import TitleDescription from '../TitleDescription/TitleDescription'
import {analyticsEvent} from '../../services/service-analytics'
import {fetchEntries} from '../../services/service-contentful'
import propertiesJSON from 'assets/data/properties.json'
import {ENDPOINT_REQUEST_PROPERTY_TOUR, getEndpoint} from '../../services/service-api'
import ModalRequestTour from '../ModalRequestTour/ModalRequestTour'
import Map from '../Map/Map'
import {addToCookieList, getCookieListDecoded} from '../../services/service-cookies'
import {COOKIES} from '../../constants/consts-cookies'

class PropertiesMap extends Component {
  constructor(props) {
    super(props)

    this.onResetFilters = this.onResetFilters.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.loadPropertiesWithTours = this.loadPropertiesWithTours.bind(this)
    this.onButtonTour = this.onButtonTour.bind(this)
    this.requestTour = this.requestTour.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)

    this.state = {
      properties: null,
      propertiesFiltered: [],
      filtersCountries: [],
      filtersFunds: [],
      filtersPlatforms: [],
      filtersStatuses: [],
      filtersSegments: [],
      filtersTours: [],
      showModal: false,
      propertyRequestData: null,
      toursRequested: null,
      tourRequested: false,
      centerLatLng: {
        lat: 56.03314,
        lng: 12.18622
      }
    }
  }

  componentDidMount() {
    const listRequested = getCookieListDecoded(COOKIES.nrep_tours_requested)
    this.setState({
      toursRequested: listRequested ? listRequested : null
    })

    this.loadPropertiesWithTours()
  }

  onResetFilters() {
    this.setState({
      propertiesFiltered: this.state.properties,
      filtersCountries: [],
      filtersFunds: [],
      filtersPlatforms: [],
      filtersStatuses: [],
      filtersSegments: [],
      filtersTours: [],
    }, () => {
      analyticsEvent('properties_map', 'map reset filters')
    })
  }

  onFilterChange(filterId, value, addRemoveBoolean) {
    // console.log('onFilterChange', {filterId, value, addRemoveBoolean})
    if(addRemoveBoolean) analyticsEvent('properties_map', 'map filter checked', value)

    this.setState(prevState => {
      const prevArray = prevState[filterId]
      const newArray = addRemoveBoolean ? [...prevArray, value] : prevArray.filter(f => f !== value)
      return {
        [filterId]: newArray
      }
    }, this.filterProperties)
  }

  filterProperties() {
    const {
      filtersCountries,
      filtersFunds,
      filtersPlatforms,
      filtersStatuses,
      filtersSegments,
      filtersTours,
    } = this.state

    this.setState(prevState => {
      const filtered = prevState.properties.filter(p => {
        if(filtersCountries.length > 0 && !filtersCountries.includes(p.country)) return false
        if(filtersFunds.length > 0 && !filtersFunds.includes(p.fund)) return false
        if(filtersPlatforms.length > 0 && !filtersPlatforms.includes(p.platform)) return false
        if(filtersStatuses.length > 0 && !filtersStatuses.includes(p.status)) return false
        if(filtersSegments.length > 0 && !filtersSegments.includes(p.segment)) return false
        if(filtersTours.length > 0 && !filtersTours.includes(p.tour)) return false
        return true
      })

      return {
        propertiesFiltered: filtered
      }
    })
  }

  async loadPropertiesWithTours() {
    const res = await fetchEntries('property')
    // console.log('res', res)

    if(res && res.length > 0) {
      // console.log('contentful', res.map(p => p.fields))
      const properties = res
        .map(p => p.fields)
        .map(p => {
          const {
            title,
            enableTour,
            propertyId,
            image,
            status,
            country,
            fund,
            segment,
            platform,
            geoLocation,
          } = p

          const imageUrl = image?.fields?.file?.url
          const image_url = imageUrl ? `${imageUrl}?w=384` : null

          return {
            id: propertyId,
            image_url: image_url,
            name: title,
            status: status?.fields?.title,
            country: country?.fields?.title,
            fund: fund?.fields?.title,
            segment: segment?.fields?.title,
            platform: platform?.fields?.title,
            latitude: geoLocation?.lat,
            longitude: geoLocation?.lon,
            tour: enableTour ? 'Tour available' : false
          }
        })

      // console.log('properties', properties)

      const getUniqueLng = (array, lat, lng) => {
        const exists = array.some(p => p.latitude === lat && p.longitude === lng)
        if(exists) {
          const newLng = (Number(lng) + .0001).toFixed(5)
          return getUniqueLng(array, lat, newLng)
        }
        return lng
      }

      const tempArray = []

      const merged = propertiesJSON
        .map(p => {
          const {id} = p
          const match = properties.find(prop => prop.id === id)
          if(match) {
            return match
          }
          return p
        })
        .filter(p => Boolean(p.latitude && p.longitude && p.name))
        .map((p, idx) => {
          const {id, latitude, longitude, images} = p
          const isDuplicate = tempArray.some(pt => pt.latitude === latitude && pt.longitude === longitude)
          if(isDuplicate) {
            const newLng = getUniqueLng(tempArray, latitude, longitude)
            const alteredP = {...p, ...{longitude: newLng}}
            tempArray[idx] = alteredP
            return alteredP
          }
          tempArray[idx] = p
          if(images === 'yes') {
            const imageUrl = `/assets/map/${id}.jpg`
            return {...p, ...{image_url: imageUrl}}
          }
          return p
        })

      this.setState({
        properties: merged,
        propertiesFiltered: merged,
      }, this.loadGoogleMapsApi)
    }
  }

  onButtonTour(event) {
    event.preventDefault()

    const {dataset} = event.target
    const {property} = dataset
    const propertyParsed = JSON.parse(property)

    const {
      id,
      name,
      tour,
    } = propertyParsed

    if(tour) {
      analyticsEvent('properties_map', 'map take tour', name)
      window.open(window.location.href + id, '_blank')
    } else {
      analyticsEvent('properties_map', 'map request tour', name)

      this.setState({
        showModal: true,
        propertyRequestData: propertyParsed,
      })
    }
  }

  async requestTour() {
    const {user} = this.props
    const {propertyRequestData} = this.state
    const {
      id,
      name,
    } = propertyRequestData

    const {
      email,
      handlerEmail,
    } = user

    analyticsEvent('properties_map', 'map request tour confirm', name)

    const params = `?userName=${user.name}&userEmail=${email}&handlerEmail=${handlerEmail}&propertyId=${id}&propertyName=${name}`

    const response = await getEndpoint(ENDPOINT_REQUEST_PROPERTY_TOUR, params, false)
    console.log('requestTour', response)

    if(response.success) {
      analyticsEvent('properties_map', 'map request tour', 'success')

      const requested = addToCookieList(COOKIES.nrep_tours_requested, id)
      this.setState({
        toursRequested: requested ? requested : requested,
        tourRequested: true
      }, () => {
        setTimeout(() => {
          this.setState({
            tourRequested: false
          })
        }, 5000)
      })
    } else {
      analyticsEvent('properties_map', 'map request tour', 'failed')
    }
  }

  onCloseModal(event) {
    console.log('onCloseModal')
    if(event) event.preventDefault()
    setTimeout(() => {
      this.setState({
        showModal: false,
        propertyRequestData: null,
      })
    }, 350)
  }

  render() {
    const {id, data} = this.props
    const {title} = data
    const {
      properties,
      propertiesFiltered,
      filtersCountries,
      filtersFunds,
      filtersPlatforms,
      filtersStatuses,
      filtersSegments,
      filtersTours,
      showModal,
      propertyRequestData,
      toursRequested,
      tourRequested,
      centerLatLng,
    } = this.state

    /*const filtersAll = [
      ...filtersTours,
      ...filtersCountries,
      ...filtersFunds,
      ...filtersPlatforms,
      ...filtersStatuses,
      ...filtersSegments,
    ]*/

    const filtersAll = [
      {id: 'tour', filters: filtersTours},
      {id: 'country', filters: filtersCountries},
      {id: 'fund', filters: filtersFunds},
      {id: 'platform', filters: filtersPlatforms},
      {id: 'status', filters: filtersStatuses},
      {id: 'segment', filters: filtersSegments},
    ]

    return (
      <>
        <section id={id} className={styles.PropertiesMap}>
          <TitleDescription data={{title}}/>

          <div className={styles.mapContainer}>
            <Map
              propertiesFiltered={propertiesFiltered}
              centerLatLng={centerLatLng}
              zoom={5}
              enableInfo={true}
              enableFullscreen={false}
              onInfoCTA={this.onButtonTour}
              toursRequested={toursRequested}
            />
            <PropertiesMapFilters
              propertiesFiltered={propertiesFiltered}
              properties={properties}
              filtersAll={filtersAll}
              onFilterChange={this.onFilterChange}
              onResetFilters={this.onResetFilters}
            />
          </div>
        </section>

        {showModal &&
        <StateConsumer>
          {globalState => {
            const {user} = globalState
            return (
              <ModalRequestTour
                user={user}
                propertyRequestData={propertyRequestData}
                tourRequested={tourRequested}
                onConfirm={this.requestTour}
                onClose={this.onCloseModal}
              />
            )
          }}
        </StateConsumer>
        }
      </>
    )
  }
}

export default withRouter(PropertiesMap)
