import React, {Component} from 'react'
import styles from './Footer.module.css'
import LogoAIFM from 'assets/images/logo-aifm.svg'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {fetchEntries} from '../../services/service-contentful'
import Logo from '../Logo/Logo'

class Footer extends Component {
  constructor(props) {
    super(props)

    this.loadData = this.loadData.bind(this)

    this.state = {
      data: null
    }
  }

  componentDidMount() {
    setTimeout(this.loadData, 500)
  }

  async loadData() {
    const data = await fetchEntries('footer')
    // console.log('FOOTER', data.fields)
    this.setState({
      data: data.fields,
    })
  }

  render() {
    const {authenticated} = this.props
    const {data} = this.state
    if(!authenticated || !data) return null

    const {
      description,
      links,
    } = data

    return (
      <>
        <footer className={styles.Footer}>
          <div className="container">
            <div className="row middle-xs">
              <div className="col-xs-12">
                <div className={styles.logoWrapper}>
                  <Logo/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-7 col-lg-7">
                {description &&
                documentToReactComponents(description)
                }
              </div>
              <div className="col-xs-12 col-sm-offset-1 col-sm-2">
                {links &&
                documentToReactComponents(links)
                }
              </div>
              <div className="col-xs-12 col-sm-2">
                <div className={styles.logoAIFMWrapper}>
                  <img src={LogoAIFM} alt="Logo AIFM" className={styles.logoAIFM}/>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
