import React, {useRef, useState} from 'react'
import styles from './ImageGallery.module.css'
import Image from '../Image/Image'
import ImageGalleryOverlay from '../ImageGalleryOverlay/ImageGalleryOverlay'
import {analyticsEvent} from '../../services/service-analytics'

const sm = [
  'col-sm-4 col-sm-offset-0',
  'col-sm-6 col-sm-offset-1',
  'col-sm-4 col-sm-offset-1',
  'col-sm-5 col-sm-offset-1',
  'col-sm-4 col-sm-offset-0',
  'col-sm-7 col-sm-offset-1',
]

export default function ImageGallery({id, data}) {
  const nodeGallery = useRef(null)
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(0)

  if(!data) return null

  const {items} = data
  const numItems = items.length

  const onImageClick = (index) => {
    const {pathname} = window.location
    const {previousSibling} = nodeGallery.current
    const h1 = previousSibling ? previousSibling.querySelector('h1') : null
    const title = h1 ? `${h1.innerText} | ` : ''
    analyticsEvent('gallery', 'gallery image click', `${pathname} | ${title}${(index + 1).toString()}`)
    setIndex(index)
    setShow(true)
  }

  const onNav = (direction) => {
    if(direction === 1 && index === (numItems - 1)) {
      setIndex(0)
      return
    }
    if(direction === -1 && index === 0) {
      setIndex(numItems - 1)
      return
    }
    setIndex(index + direction)
  }

  const onCloseOverlay = () => {
    setShow(false)
  }

  return (
    <>
      <section id={id} ref={nodeGallery} className={styles.ImageGallery}>
        <div className="container">
          <div className="row middle-sm">
            {items && items.length > 0 &&
            items.map((item, idx) => {
              const mod = idx % 6
              return (
                <div key={idx} className={`col-xs-12 ${sm[mod]}`}>
                  <Image
                    index={idx}
                    data={item}
                    width={1024}
                    showCaption={true}
                    classNameFigure={styles.figure}
                    className={styles.image}
                    onClick={onImageClick}
                  />
                </div>
              )
            })
            }
          </div>
        </div>
      </section>

      {show &&
      <ImageGalleryOverlay
        data={data}
        index={index}
        setShow={onCloseOverlay}
        setIndex={setIndex}
        onNav={onNav}
      />
      }
    </>
  )
}
