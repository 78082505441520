import React from 'react'
import styles from './PropertyKeyFigures.module.css'
import PropertyKeyFiguresItem from '../PropertyKeyFiguresItem/PropertyKeyFiguresItem'

export default function PropertyKeyFigures({targetNetIrr, targetNetMoC, fund, status}) {
  return (
    <div className={styles.PropertyKeyFigures}>
      {targetNetIrr &&
      <PropertyKeyFiguresItem
        title="Target Net IRR"
        description={targetNetIrr}
      />
      }
      {targetNetMoC &&
      <PropertyKeyFiguresItem
        title="Target Net MOC"
        description={targetNetMoC}
      />
      }
      {fund &&
      <PropertyKeyFiguresItem
        title="Fund"
        description={fund.fields.title}
      />
      }
      {status &&
      <PropertyKeyFiguresItem
        title="Status"
      >
          <span className={styles.status}>
             <span className={`dot ${status.fields.title}`}/>
             <span className={styles.statusLabel}>{status.fields.title}</span>
          </span>
      </PropertyKeyFiguresItem>
      }
    </div>
  )
}
