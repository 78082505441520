// const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID
// const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
const space = 'vufqx50mipp1'
const accessToken = '65gkr-Yfhs9g3t4nopWzR2e5KXBqw-cHGCAWAXmzBRw'

const client = require('contentful').createClient({
  space: space,
  accessToken: accessToken,
})

export async function fetchEntries(contentType, slug, locale) {
  /* 'include' controls how many levels deep the nested data is loaded (max 10 levels) */
  const payload = {
    include: 10,
    ...contentType && {'content_type': contentType},
    ...slug && {'fields.slug': slug},
    ...locale && {locale: locale},
  }
  // console.log('payload', payload)
  const response = await client.getEntries(payload)
  if (response.items) {
    if(slug || response.items.length === 1) return response.items[0]
    return response.items
  }
  console.log(`Error getting Entries for ${contentType}.`)
}

export async function fetchEntry(contentType, field, value) {
  /* 'include' controls how many levels deep the nested data is loaded (max 10 levels) */

  const key = `fields.${field}`

  const payload = {
    include: 10,
    ...contentType && {'content_type': contentType},
    ...field && {[key]: value}
  }
  // console.log('payload', payload)
  const response = await client.getEntries(payload)
  if (response.items) {
    if(response.items.length === 1) return response.items[0]
    return response.items[0]
  }
  console.log(`Error getting Entries for ${contentType}.`)
}
