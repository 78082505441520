import React, {Component} from 'react'
import styles from './LoaderSpinner.module.css'
import {getViewPortHeight, getViewPortWidth} from '../../services/service-utils'

class LoaderSpinner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      radius: 5,
      vw: 0,
      vh: 0,
    }
  }

  componentDidMount() {
    this.setState({
      vw: getViewPortWidth(),
      vh: getViewPortHeight()
    })
  }

  render() {
    const {className} = this.props

    const {
      vw,
      vh
    } = this.state

    const max = Math.max(vw, vh)

    const styleLoader = {
      width: `${max}px`,
      height: `${max}px`,
    }

    return (
      <div className={`${styles.LoaderSpinner} ${className ? className : ''}`} style={styleLoader}>
        <svg className={styles.svg} id="loader" height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
          <circle className={styles.progress} cx="50" cy="50" r="5" strokeWidth=".1" fill="none"/>
        </svg>
      </div>
    )
  }
}

export default LoaderSpinner
