import React from 'react'
import {Link} from 'react-router-dom'
import styles from './Header.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import Logo from '../Logo/Logo'

export default function Header({authenticated, location, firstIsHero}) {
  const {pathname} = location
  const showButtonBack = (pathname !== '/' && pathname !== '/test') && authenticated

  return (
    <>
      <header className={firstIsHero ? styles.HeaderWithHero : styles.Header}>
        <div className="container">
          <div className="row middle-xs">
            <div className="col-xs-12">
              <Logo/>
            </div>
          </div>
        </div>
      </header>

      {showButtonBack &&
      <nav className={styles.nav}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <Link to="/" className={styles.linkBack}>
                <Svg id={ICONS.arrowLeft}/>
                <span>BACK</span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      }
    </>
  )
}
