import React from 'react'
import styles from './Image.module.css'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {isDesktop} from '../../services/service-utils'

export default function Image(props) {
  const {
    index,
    data,
    alt,
    showCaption,
    className,
    classNameFigure,
    width,
    background,
    onClick,
  } = props

  if(!data) return null
  const {file, image, caption} = data.fields
  const url = file?.url || image?.fields?.file?.url
  if(!url) return null

  const w = isDesktop() ? width : width / 2

  const src = width ? `${url}?${w ? `w=${w}` : ''}` : url
  // console.log('imageUrl', src)

  const imageNode = background ? (
    <div
      style={{backgroundImage: `url(${src})`}}
      className={className ? className : ''}
    />
  ) : (
    <img
      src={src}
      alt={alt ? alt : ''}
      className={className ? className : ''}
    />
  )

  return (
    <figure className={`${styles.figure} ${classNameFigure ? classNameFigure : ''}`}>
      {onClick &&
      <button
        onClick={() => onClick(index)}
        className={styles.button}
      >
        {imageNode}
      </button>
      }
      {!onClick &&
      imageNode
      }

      {showCaption && caption &&
      <figcaption>{documentToReactComponents(caption)}</figcaption>
      }
    </figure>

  )
}
