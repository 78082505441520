import React, {Component} from 'react'
import styles from './Styleguide.module.css'
import Svg from '../Svg/Svg'
import TitleDescription from '../TitleDescription/TitleDescription'

class Styleguide extends Component {
  render() {
    const {title} = this.props.data

    const ids = [...document.querySelectorAll('symbol[id^="svg-"]')].map(el => el.getAttribute('id'))
    const icons = ids.map(id => {
      const iconId = id.slice(4)
      return (
        <div key={id} className="col-xs-12 col-sm-4">
          <div className={styles.icon}>
            <label>{iconId}</label>
            <Svg id={iconId}/>
          </div>
        </div>
      )
    })

    return (
      <>
        <TitleDescription data={{title}}/>

        <div className={styles.Styleguide}>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Colors</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <div className="row">
                    <div className="col-xs-3">
                      <h5>Primary</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3">
                      <div className={styles.colorBlueDark}>
                        <small>blue-dark</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorGraphite}>
                        <small>graphite</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorBlack}>
                        <small>black</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorWhite}>
                        <small>white</small>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-3">
                      <h5>Secondary</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-3">
                      <div className={styles.colorBlue}>
                        <small>blue</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorGreen}>
                        <small>green</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorPurple}>
                        <small>purple</small>
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className={styles.colorYellow}>
                        <small>yellow</small>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Typeface</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <h5>Gotham</h5>
                  <span style={{fontFamily: 'Gotham', fontWeight: '400'}}>Gotham Regular</span>
                  <br/>
                  <span style={{fontFamily: 'Gotham', fontWeight: '500'}}>Gotham Medium</span>
                  <br/>
                  <span style={{fontFamily: 'Gotham', fontWeight: '700'}}>Gotham Bold</span>
                  <br/>
                  <br/>
                  <h5>Lato</h5>
                  <span style={{fontFamily: 'Lato', fontWeight: '400'}}>Lato</span>
                  <br/>
                  <span style={{fontFamily: 'Lato', fontWeight: '400', fontStyle: 'italic'}}>Lato</span>
                  <br/>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Typography</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <h1>BEDRE EJENDOMME FOR MENNESKER OG BYER</h1>
                  <h2>BEDRE EJENDOMME FOR MENNESKER OG BYER</h2>
                  <h3>BEDRE EJENDOMME FOR MENNESKER OG BYER</h3>
                  <h4>BEDRE EJENDOMME FOR MENNESKER OG BYER</h4>
                  <h5>BEDRE EJENDOMME FOR MENNESKER OG BYER</h5>
                  <h6>BEDRE EJENDOMME FOR MENNESKER OG BYER</h6>
                  <p>Paragraph text. Sed ut <a href="/">perspiciatis unde omnis iste natus</a> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                  <p><strong>Bold text</strong></p>
                  <p><em>Italic text</em></p>
                  <p>
                    <small>Small text</small>
                  </p>
                  <a href="/">Link text</a>
                  <blockquote>"Quote the quick brown fox jumps over the lazy dog"
                    <cite>, Flash Gordon</cite>
                  </blockquote>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Lists</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <ul>
                    <li>Unordered list item</li>
                    <li>Unordered list item
                      <ul>
                        <li>Unordered list item</li>
                        <li>Unordered list item</li>
                      </ul>
                    </li>
                    <li>Unordered list item</li>
                  </ul>
                  <ol>
                    <li>Ordered list item</li>
                    <li>Ordered list item
                      <ol>
                        <li>Ordered list item</li>
                        <li>Ordered list item</li>
                      </ol>
                    </li>
                    <li>Ordered list item</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Buttons</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="button-group">
                        <button className="button">Button primary</button>
                        <button className="button" disabled>Button disabled</button>
                        <button className="button secondary">Button secondary</button>
                        <button className="button secondary" disabled>Button secondary disabled</button>
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="button-group">
                        <a className="button" href="/">Link button primary</a>
                        <a className="button" href="/" aria-disabled>Link button disabled</a>
                        <a className="button secondary" href="/">Link button secondary</a>
                        <a className="button secondary" href="/" aria-disabled>Link button secondary disabled</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className={styles.bgDark}>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Buttons inverted</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="button-group">
                        <button className="button inverted">Button primary</button>
                        <button className="button inverted" disabled>Button disabled</button>
                        <button className="button secondary inverted">Button secondary</button>
                        <button className="button secondary inverted" disabled>Button secondary disabled</button>
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div className="button-group">
                        <a className="button inverted" href="/">Link button primary</a>
                        <a className="button inverted" href="/" aria-disabled>Link button disabled</a>
                        <a className="button secondary inverted" href="/">Link button secondary</a>
                        <a className="button secondary inverted" href="/" aria-disabled>Link button secondary disabled</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Forms</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <form>
                    <label htmlFor="my-input">Name</label>
                    <input id="my-input" type="text" placeholder="Your name"/>
                    <textarea defaultValue="The quick brown fox jumps over the lazy dog"/>

                    <label className="checkbox">
                      <input type="checkbox"/>
                      <span className="checklabel">Check label</span>
                      <span className="checkmark"/>
                    </label>

                    <label className="radio">
                      <input type="radio" name="gender" value="female"/>
                      <span className="checklabel">Female</span>
                      <span className="checkmark"/>
                    </label>
                    <label className="radio">
                      <input type="radio" name="gender" value="male"/>
                      <span className="checklabel">Male</span>
                      <span className="checkmark"/>
                    </label>

                  </form>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Icons</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <div className="row">
                    {icons}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Grid system</h1>
                </div>
                <div className="col-xs-12 col-md-6 col-md-offset-1">
                  <a href="http://flexboxgrid.com" target="_blank" rel="noopener noreferrer">Flexboxgrid.com</a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h1>Break points</h1>
                </div>
                <div className="col-xs-12 col-md-3 col-md-offset-1">
                  <table style={{tableLayout: 'fixed', width: '100%'}}>
                    <thead style={{textAlign: 'left'}}>
                    <tr>
                      <th style={{width: '40%'}}>size</th>
                      <th>min</th>
                      <th style={{width: '25%', textAlign: 'center'}}/>
                      <th>max</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>xxs-down</td>
                      <td>0</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td>xs-down</td>
                      <td>0</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td>767</td>
                    </tr>
                    <tr>
                      <td>sm</td>
                      <td>768</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td/>
                    </tr>
                    <tr>
                      <td>md</td>
                      <td>1024</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td/>
                    </tr>
                    <tr>
                      <td>lg</td>
                      <td>1200</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td/>
                    </tr>
                    <tr>
                      <td>xl</td>
                      <td>1440</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td/>
                    </tr>
                    <tr>
                      <td>xxl</td>
                      <td>1920</td>
                      <td style={{textAlign: 'center'}}>-</td>
                      <td/>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>

      </>
    )
  }
}

export default Styleguide
