import React from 'react'
import styles from './PropertyKeyFiguresItem.module.css'

export default function PropertyKeyFiguresItem({title, description, children}) {
  return (
    <dl className={styles.PropertyKeyFiguresItem}>
      {title &&
      <dt>{title}</dt>
      }
      {description &&
      <dd>
        {description}
      </dd>
      }
      {children &&
      <dd>
        {children}
      </dd>
      }
    </dl>
  )
}
