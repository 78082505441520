import React, {useEffect, useRef} from 'react'
import {useSwipeable} from 'react-swipeable'
import styles from './ImageGalleryOverlay.module.css'
import Image from '../Image/Image'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import DotNavigation from '../DotNavigation/DotNavigation'
import {analyticsEvent} from '../../services/service-analytics'
import Modal from '../Modal/Modal'

export default function ImageGalleryOverlay({data, index, setShow, setIndex, onNav}) {
  const nodeOverlay = useRef(null)
  const nodeButtonClose = useRef(null)
  const nodeButtonPrev = useRef(null)
  const nodeButtonNext = useRef(null)

  const handlers = useSwipeable({
    onSwipedLeft: () => onNav(1),
    onSwipedRight: () => onNav(-1),
    preventDefaultTouchmoveEvent: true
  })

  const onKeyUp = (event) => {
    switch(event.code) {
      case 'Escape':
        setShow(false)
        break
      case 'ArrowLeft':
        onNav(-1)
        break
      case 'ArrowRight':
        onNav(1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp)

    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  })

  useEffect(() => {
    if(nodeOverlay && nodeOverlay.current) nodeOverlay.current.scrollTop = 0
  }, [index])

  if(!data) return null
  const {items} = data
  const numItems = items.length

  return (
    <Modal onClose={() => setShow(false)}>
      <section
        ref={nodeOverlay}
        className={styles.ImageGalleryOverlay}
        {...handlers}
      >
        <div className={styles.imagesContainer}>
          <div className={styles.scroller}>
            {items && numItems > 0 &&
            items.map((item, idx) => {
              const styleItem = {
                opacity: `${index === idx ? 1 : 0}`
              }
              return (
                <div key={idx} className={styles.item} style={styleItem}>
                  <Image
                    index={idx}
                    data={item}
                    width={1920}
                    showCaption={false}
                    background={false}
                    className={styles.image}
                    classNameFigure={styles.figure}
                  />
                </div>
              )
            })
            }
          </div>
        </div>

        {numItems > 1 &&
        <>
          <DotNavigation
            numItems={numItems}
            index={index}
            setIndex={setIndex}
            className={styles.dots}
          />

          <button ref={nodeButtonPrev} className={styles.buttonLeft} onClick={() => {
            analyticsEvent('gallery', 'gallery overlay nav', 'left')
            onNav(-1)
          }}><Svg id={ICONS.navLeft}/></button>
          <button ref={nodeButtonNext} className={styles.buttonRight} onClick={() => {
            analyticsEvent('gallery', 'gallery overlay nav', 'right')
            onNav(1)
          }}><Svg id={ICONS.navRight}/></button>
        </>
        }

        <button ref={nodeButtonClose} className={styles.buttonClose} onClick={() => setShow(false)}><Svg id={ICONS.plus}/></button>

      </section>
    </Modal>
  )
}
