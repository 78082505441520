import React, {Component} from 'react'
import styles from './ModalRequestTour.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {fetchEntries} from '../../services/service-contentful'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Modal from '../Modal/Modal'

class ModalRequestTour extends Component {
  constructor(props) {
    super(props)

    this.onConfirm = this.onConfirm.bind(this)
    this.beforeClose = this.beforeClose.bind(this)

    this._isMounted = false

    this.state = {
      data: null,
      show: false,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.loadData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async loadData() {
    const response = await fetchEntries('requestTourResponse')
    console.log('requestTourResponse', response.fields)

    if(response) {
      if(!this._isMounted) return
      this.setState({
        data: response.fields,
        show: true
      })
    }
  }

  onConfirm() {
    const {onClose, onConfirm} = this.props
    if(onConfirm) onConfirm()

    setTimeout(() => {
      if(!this._isMounted) return
      this.setState({
        show: false
      }, () => {
        if(onClose) onClose()
      })
    }, 5000)
  }

  beforeClose() {
    if(!this._isMounted) return
    const {onClose} = this.props

    this.setState({
      show: false
    }, () => {
      if(onClose) setTimeout(onClose, 300)
    })
  }

  render() {
    const {
      user,
      propertyRequestData,
      tourRequested,
    } = this.props

    console.log('tourRequested', tourRequested)

    const {name} = propertyRequestData || {name: 'Test property'}

    const {
      email,
    } = user
    const {
      show,
      data,
    } = this.state

    const desc = data?.description ? JSON.parse(JSON.stringify(data?.description)
      .replace('USER_EMAIL', email)
      .replace('PROPERTY_NAME', name)
    ) : ''

    return (
      <Modal onClose={this.beforeClose}>
        <div className={show ? styles.show : styles.ModalRequestTour}>
          <button className={styles.bgClose} onClick={this.beforeClose}/>

          <div className={styles.background}>
            {data &&
            <div className={styles.content}>
              {!tourRequested &&
              <>
                <h2 className={styles.title}>
                  {data.title &&
                  data.title
                  }
                </h2>

                <div>
                  {desc &&
                  documentToReactComponents(desc)
                  }
                </div>

                <div className={styles.buttons}>
                  <button onClick={this.onConfirm} className={`button ${styles.buttonOk}`}>
                    <span>Request</span>
                  </button>
                  <button onClick={this.beforeClose} className={`button link ${styles.buttonOk}`}>
                    Cancel
                  </button>
                </div>
              </>
              }

              {tourRequested &&
              <>
                <h2 className={styles.title}>Success</h2>
                <Svg id={ICONS.checkmark} className={styles.iconSuccessBig}/>
                <div className={styles.buttons}>
                  <button onClick={this.beforeClose} className={`button ${styles.buttonOk}`}>
                    OK
                  </button>
                </div>
              </>
              }
            </div>
            }

            <button onClick={this.beforeClose} className={styles.buttonClose}>
              <Svg id={ICONS.plus}/>
            </button>

          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalRequestTour
