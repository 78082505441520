import React, {Component} from 'react'
import styles from './ModalOnBoarding.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {fetchEntries} from '../../services/service-contentful'
import Modal from '../Modal/Modal'
import OnBoardingScreen from './OnBoardingScreen/OnBoardingScreen'
import DotNavigation from '../DotNavigation/DotNavigation'

class ModalOnBoarding extends Component {
  constructor(props) {
    super(props)

    this.beforeClose = this.beforeClose.bind(this)
    this.setIndex = this.setIndex.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onSkip = this.onSkip.bind(this)

    this.state = {
      data: null,
      show: false,
      index: 0,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    const response = await fetchEntries('modalOnboarding')
    if(response) {
      this.setState({
        data: response
      }, () => {
        setTimeout(() => {
          this.setState({
            show: true
          })
        }, 500)
      })
    }
  }

  beforeClose() {
    this.setState({
      show: false
    }, () => {
      const {onClose} = this.props
      if(onClose) setTimeout(onClose, 300)
    })
  }

  setIndex(index) {
    this.setState({
      index: index
    })
  }

  onNext() {
    const {data, index} = this.state
    const {screens} = data.fields
    const numItems = screens.length

    const nextIndex = index + 1
    if(nextIndex >= numItems) {
      this.beforeClose()
    }

    this.setState({
      index: nextIndex
    })
  }

  onSkip() {
    this.beforeClose()
  }

  render() {
    const {
      show,
      data,
      index,
    } = this.state

    if(!data || !data.fields) return null
    const {fields} = data
    const {screens} = fields

    const numItems = screens.length

    const isLastItem = index === numItems - 1

    const styleScreens = {
      width: `${numItems * 100}%`,
      transform: `translateX(-${index * (100/numItems)}%)`
    }

    return (
      <Modal onClose={this.beforeClose}>
        <div className={show ? styles.show : styles.ModalOnBoarding}>
          <button disabled className={styles.bgClose} onClick={this.beforeClose}/>

          <div className={styles.background}>
            {data &&
            <div className={styles.content}>

              <div className={styles.screens} style={styleScreens}>
                {screens && screens.length > 0 &&
                screens.map((screen, idx) => <OnBoardingScreen key={idx} data={screen}/>)
                }
              </div>

              <DotNavigation
                numItems={numItems}
                index={index}
                setIndex={this.setIndex}
                className={styles.dots}
              />

              <div className={styles.buttons}>
                <button onClick={this.onNext} className={`button ${styles.buttonOk}`}>{isLastItem ? 'Get started' : 'Next'}</button>
                <button onClick={this.onSkip} className={`${styles.buttonSkip}`}>Skip</button>
              </div>
            </div>
            }

            <button onClick={this.beforeClose} className={styles.buttonClose}>
              <Svg id={ICONS.plus}/>
            </button>

          </div>
        </div>
      </Modal>
    )
  }
}

export default ModalOnBoarding
