import React from 'react'
import styles from './DataListItem.module.css'

export default function DataListItem({data}) {
  if(!data || !data.fields) return null
  const {fields} = data
  const {key, value, unit} = fields
  // console.log('DataListItem', key, value, unit)

  return (
    <li className={styles.DataListItem}>
      <dl>
        <dt>{key}</dt>
        <dd>{value}{unit && <small>{unit}</small>}</dd>
      </dl>
    </li>
  )
}
