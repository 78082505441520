import React from 'react'
import styles from './Hero.module.css'
import Image from '../Image/Image'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import {getViewPortHeight, scrollToElement} from '../../services/service-utils'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'

export default function Hero(props) {
  const {id, data} = props
  const {
    title,
    description,
    image,
    vimeoUrl,
    primaryCtaTitle,
    primaryCtaSection,
    secondaryCtaTitle,
    secondaryCtaSection,
  } = data

  if(!image && !vimeoUrl) return null

  const onCtaClick = (data) => {
    const section = document.getElementById(data?.sys?.id)
    if(section) scrollToElement(section)
  }

  const onScroll = () => {
    const page = document.getElementById(id)
    const section = page.nextSibling
    if(section) scrollToElement(section)
  }

  const viewHeight = getViewPortHeight()
  const styleHeight = {
    height: `${viewHeight}px`
  }

  return (
    <section
      id={id}
      className={styles.Hero}
      style={styleHeight}
    >
      <div
        className={styles.background}
        style={styleHeight}
      >
        {image &&
        <Image
          data={image}
          width={1920}
          className={styles.image}
        />
        }
        {vimeoUrl &&
        <VimeoPlayer
          vimeoUrl={vimeoUrl}
          background={true}
          className={styles.video}
        />
        }
      </div>

      <div className={styles.content}>
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              {title &&
              <h1 className={styles.title}>{title}</h1>
              }
              {description &&
              <div className={styles.description}>
                {documentToReactComponents(description)}
              </div>
              }
              <div className={`button-group ${styles.ctas}`}>
                {primaryCtaTitle &&
                <button className={`button primary`} onClick={() => onCtaClick(primaryCtaSection)}>{primaryCtaTitle}</button>
                }
                {secondaryCtaTitle &&
                <button className={`button secondary`} onClick={() => onCtaClick(secondaryCtaSection)}>{secondaryCtaTitle}</button>
                }
              </div>

              <button
                className={styles.buttonScroll}
                onClick={onScroll}
              >
                <Svg id={ICONS.navDown} className={styles.iconNavDown}/>
              </button>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
