import React from 'react'
import styles from './Svg.module.css'

export default function Svg(props) {
  const {id, className, style} = props

  return (
    <svg className={`${styles[id]}${className ? ' ' + className : ''}`}  style={style ? style : null}>
      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#svg-${props.id}`}/>
    </svg>
  )
}
