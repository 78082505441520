import React from 'react'
import styles from './InfoWindow.module.css'

export default function InfoWindow({property, toursRequested}) {
  const {
    id,
    fund,
    image_url,
    name,
    platform,
    segment,
    status,
    tour,
  } = property

  const disabled = Boolean(toursRequested && toursRequested.length && toursRequested.includes(id))

  return (
    <div className={styles.InfoWindow}>
      {image_url &&
      <img
        src={image_url}
        alt={name}
      />
      }
      <div className={image_url ? styles.contentImage : styles.content}>
        {name &&
        <span>{name}</span>
        }
        {fund &&
        <span>{fund}</span>
        }
        {segment &&
        <span>{segment}</span>
        }
        {platform &&
        <span>{platform}</span>
        }
        {status &&
        <span className={styles.status}>
           <span className={`dot ${status}`}/>
           <span className={styles.label}>{status}</span>
        </span>
        }

        {tour &&
        <button
          className="button"
          data-property={JSON.stringify(property)}
        >
          TAKE TOUR
        </button>
        }

        {!tour &&
        <button
          className="button secondary"
          data-property={JSON.stringify(property)}
          disabled={disabled}
        >
          {disabled ? 'TOUR REQUESTED' : 'REQUEST TOUR'}
        </button>
        }

      </div>
    </div>
  )
}
