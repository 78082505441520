import React, {Component} from 'react'
import styles from './PropertyFeatured.module.css'
import {Link} from 'react-router-dom'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Dotdotdot from 'react-dotdotdot'
import Image from '../Image/Image'
import {getViewPortHeight, isDesktop} from '../../services/service-utils'
import PropertyTags from '../PropertyTags/PropertyTags'
import {analyticsEvent} from '../../services/service-analytics'

class PropertyFeatured extends Component {
  constructor(props) {
    super(props)

    this.onImageClick = this.onImageClick.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)
  }

  onImageClick() {
    const {data} = this.props
    const {title} = data
    analyticsEvent('property_featured', 'featured click image', title)
  }

  onLinkClick() {
    const {data} = this.props
    const {title} = data
    analyticsEvent('property_featured', 'featured click cta', title)
  }

  render() {
    const {data, idx, isActive, show, direction} = this.props
    const {slug, content} = data

    const property = content.find(item => item?.sys?.contentType?.sys?.id === 'property')
    if(!property) return null

    const {fields} = property

    const {
      title,
      description,
      image,
      platform,
      segment,
    } = fields

    const stylePropertyFeatured = {
      opacity: show ? 1 : 0,
      pointerEvents: show ? 'auto' : 'none'
    }

    const styleImageLink = {
      transform: isDesktop() ? `scale(${show ? 1 : 1.01})` : '',
    }

    const linkPath = `/${slug}`
    const numLines = Math.round((getViewPortHeight() - 200) / 80)
    const directionRight = Boolean(direction > 0)

    return (
      <section
        id={`pf_${idx}`}
        className={styles.PropertyFeatured}
        style={stylePropertyFeatured}
      >

        {image &&
        <Link
          to={linkPath}
          className={styles.imageLink}
          style={styleImageLink}
          onClick={this.onImageClick}
        >
          <Image
            data={image}
            className={directionRight ? (isActive ? styles.imageNextIn : styles.imageNextOut) : (isActive ? styles.imagePrevIn : styles.imagePrevOut)}
            width={1920}
          />
        </Link>
        }

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-4 col-xl-3 col-xxl-2">

              <div className={styles.background}/>

              <div className={styles.content}>

                {platform?.fields?.logo &&
                <Image
                  data={platform?.fields?.logo}
                  className={styles.logoPlatform}
                  width={384}
                />
                }
                {!platform?.fields?.logo &&
                <span className={styles.logoPlatform}/>
                }

                {(title || segment) &&
                <PropertyTags
                  data={{title, segment}}
                  horizontal={false}
                  className={styles.tags}
                />
                }

                <div>
                  {description &&
                  <div className={styles.description}>
                    <Dotdotdot clamp={numLines}>
                      {documentToReactComponents(description)}
                    </Dotdotdot>
                  </div>
                  }
                  <Link
                    to={linkPath}
                    className="button"
                    onClick={this.onLinkClick}
                  >
                    Take tour
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PropertyFeatured
