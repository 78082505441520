import {isDevEnvironment} from './service-utils'

export const analyticsEvent = (category = '', action = '', label = '', value = '') => {
  if(!window.gtag || typeof window.gtag !== 'function' ) {
    console.log('window.gtag (Google Analytics) disabled.', {category, action, label, value})
    return
  }

  if(isDevEnvironment) {
    console.log('GA', {category, action, label, value})
    return
  }
  window.gtag('event',
    action,
    {
      'event_category': category,
      'event_label': label,
      'value': value
    })
}

