import React, {Component} from 'react'
import styles from './PropertiesMapFilterList.module.css'

class PropertiesMapFilterList extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    const {id, checked} = event.target
    const {onFilterChange, filterId} = this.props
    onFilterChange(filterId, id, checked)
  }

  render() {
    const {
      propertiesFiltered,
      filtersAll,
      data,
      field,
    } = this.props

    if(!data) return null

    const current = filtersAll.find(f => f.id === field)
    const {filters} = current
    // console.log('filters', filters)

    return (
      <>
        <h6 className={styles.listTitle}>{field}</h6>
        <ul className={styles.PropertiesMapFilterList}>
          {data &&
          data.map((title, idx) => {
            const itemsFiltered = propertiesFiltered.filter(p => p[field] === title)
            const numItemsFiltered = itemsFiltered.length
            const hasItemsFiltered = numItemsFiltered !== 0
            const checked = filters.includes(title)
            return (
              <li key={idx}>
                <label className="checkbox" aria-disabled={!hasItemsFiltered}>
                  <input type="checkbox" checked={checked} id={title} onChange={this.onChange}/>
                  <span className="checklabel">{title} ({numItemsFiltered})</span>
                  <span className="checkmark"/>
                </label>
              </li>
            )
          })
          }
        </ul>
      </>
    )
  }
}

export default PropertiesMapFilterList
