import React from "react"
import styles from "./VimeoPlayer.module.css"
import Player from '@vimeo/player'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'
import {analyticsEvent} from '../../services/service-analytics'
import {getViewPortHeight, getViewPortWidth, isDesktop} from '../../services/service-utils'
import {isDevEnvironment} from '../../services/service-utils'

class VimeoPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.destroyVideo = this.destroyVideo.bind(this)
    this.getVideoSize = this.getVideoSize.bind(this)
    this.onError = this.onError.bind(this)
    this.onPlaying = this.onPlaying.bind(this)
    this.onEnded = this.onEnded.bind(this)
    this.onButtonPlay = this.onButtonPlay.bind(this)
    this.videoRef = React.createRef()
    this.videoContainerRef = React.createRef()

    this._isMounted = false

    this.state = {
      initiated: false,
      viewHeight: getViewPortHeight(),
      videoWidth: 0,
      videoHeight: 0,
      opacity: props.background ? 0 : 1,
      showSpinner: true,
    }
  }

  componentDidMount() {
    this._isMounted = true
    const {vimeoUrl} = this.props

    // console.log('vimeoUrl', vimeoUrl)

    if(this.videoRef.current && vimeoUrl && !isDevEnvironment) {
      this.newVideo()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getVideoSize)
    this.destroyVideo()
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {vimeoUrl} = this.props
    if(prevProps.vimeoUrl !== vimeoUrl) {
      this.destroyVideo()

      this.setState({
        initiated: false,
        viewHeight: getViewPortHeight(),
        videoWidth: 0,
        videoHeight: 0,
        opacity: this.props.background ? 0 : 1,
        showSpinner: true,
      })

      if(this.videoRef.current && vimeoUrl && !isDevEnvironment) {
        this.newVideo()
      }
    }
  }

  destroyVideo() {
    if(this.player) {
      this.player.off('error', this.onError)
      this.player.off('play', this.onPlaying)
      this.player.destroy()
    }
  }

  newVideo() {
    const {vimeoUrl, background} = this.props

    const deskTop = isDesktop()

    this.player = new Player( this.videoRef.current, {
      url: vimeoUrl,
      color: '00A0D2',
      autoplay: true,
      loop: true,
      autopause: false,
      background: background,
      quality: background ? (deskTop ? '540p' : '240p') : ''
    })

    this.player.setMuted(true)

    this.player.on('error', this.onError)
    this.player.on('play', this.onPlaying)
    this.player.on('ended', this.onEnded)
    window.addEventListener("resize", this.getVideoSize)
  }

  onError(event) {
    if(!this._isMounted) return
    console.log('onError', event)

    this.setState({
      showSpinner: false,
      opacity: 1
    })
  }

  onPlaying() {
    if(!this._isMounted) return

    this.getVideoSize()

    this.setState({
      showSpinner: false,
      opacity: 1
    })
  }

  onEnded() {
    if(!this._isMounted) return

    this.setState({
      initiated: false
    })

    this.player.setAutopause(false)
    this.player.setLoop(true)
    this.player.setMuted(true)
    this.player.setCurrentTime(0)
    this.player.play()
  }

  async onButtonPlay() {
    this.setState({
      initiated: true
    })

    this.player.setAutopause(true)
    this.player.setLoop(false)
    this.player.setMuted(false)
    this.player.setCurrentTime(0)
    this.player.play()

    const title = await this.player.getVideoTitle()
    if(title) analyticsEvent('video', 'video click play', title)
  }

  async getVideoSize() {
    const {background} = this.props
    if(!this.player) return
    const viewWidth = getViewPortWidth()
    const viewHeight = getViewPortHeight()

    const videoWidth = await this.player.getVideoWidth()
    const videoHeight = await this.player.getVideoHeight()

    if(background) {
      const viewRatio = viewHeight / viewWidth

      if(viewRatio < .5625) {
        this.setState({
          viewHeight: viewHeight,
          videoWidth: viewWidth,
          videoHeight: (viewWidth / 16) * 9,
        })
      } else {
        this.setState({
          viewHeight: viewHeight,
          videoWidth: (viewHeight / 9) * 16,
          videoHeight: viewHeight
        })
      }
    } else {
      const ratio = videoHeight / videoWidth
      const containerWidth = this.videoContainerRef.current ? this.videoContainerRef.current.getBoundingClientRect().width : 0

      this.setState({
        viewHeight: viewHeight,
        videoWidth: containerWidth,
        videoHeight: containerWidth * ratio
      })
    }
  }

  render() {
    const {
      vimeoUrl,
      className,
      background,
    } = this.props

    if(!vimeoUrl) return null

    const {
      viewHeight,
      videoWidth,
      videoHeight,
      initiated,
      showSpinner,
      opacity,
    } = this.state

    const videoW = videoWidth !== 0 ? `${videoWidth}px` : '100%'
    const videoH = videoHeight !== 0 ? `${videoHeight}px` : '100%'
    const styleFrame = {
      width: videoW,
      height: videoH,
      opacity: opacity
    }

    return (
      <div
        ref={this.videoContainerRef}
        className={`${background ? styles.VimeoPlayerBackground : styles.VimeoPlayer}${className ? ' ' + className : ''}`}
        style={background ? {height: `${viewHeight}px`} : {}}
      >
        {!background && showSpinner &&
        <LoaderSpinner className={styles.loader}/>
        }

        <div className={styles.frame} style={styleFrame}>
          <div className={styles.videoContainer}>
            <div
              ref={this.videoRef}
              className={styles.videoPlayer}
            />
          </div>
        </div>

        {!initiated && !background &&
        <button className={styles.buttonPlay} onClick={this.onButtonPlay}>
          <Svg id={ICONS.play} className={styles.iconPlay}/>
        </button>
        }
      </div>
    )
  }
}

export default VimeoPlayer
