import React from 'react'
import styles from './PropertyHeader.module.css'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Image from '../Image/Image'
import PropertyKeyFigures from '../PropertyKeyFigures/PropertyKeyFigures'
import PropertyTags from '../PropertyTags/PropertyTags'

export default function PropertyHeader({data}) {
  const {
    title,
    showPropertyHeader,
    image,
    description,
    targetNetIrr,
    targetNetMoC,
    fund,
    status,
    segment,
    platform,
  } = data

  if(!showPropertyHeader) return null

  return (
    <section className={styles.PropertyHeader}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">

            <div className={styles.features}>
              <div className={styles.keyFeaturesMobile}>
                <div className={styles.logoPlatformContainer}>
                  {platform?.fields?.logo &&
                  <Image
                    data={platform?.fields?.logo}
                    className={styles.logoPlatform}
                    width={512}
                    background={true}
                  />
                  }
                </div>
                {(title || segment) &&
                <PropertyTags data={{title, segment}} className={styles.tags} horizontal={true}/>
                }
                {image &&
                <div className={styles.imageWrapper}>
                  <Image
                    data={image}
                    alt={title}
                    className={styles.image}
                    width={1024}
                    background={true}
                  />
                </div>
                }
                <div className={styles.description}>
                  {description &&
                  documentToReactComponents(description)
                  }
                </div>

              </div>


              <div className={styles.keyFeaturesDesktop}>
                {image &&
                <div className={styles.imageWrapper}>
                  <Image
                    data={image}
                    alt={title}
                    className={styles.image}
                    width={1024}
                    background={true}
                  />
                </div>
                }
                <div className={styles.description}>
                  {(title || segment) &&
                  <PropertyTags
                    data={{title, segment}}
                    horizontal={true}
                    className={styles.tags}
                  />
                  }

                  {description &&
                  documentToReactComponents(description)
                  }
                </div>
                <div className={styles.logoPlatformContainer}>
                  <Image
                    data={platform?.fields?.logo}
                    className={styles.logoPlatform}
                    width={512}
                    background={true}
                  />
                </div>
              </div>

              <PropertyKeyFigures
                targetNetIrr={targetNetIrr}
                targetNetMoC={targetNetMoC}
                fund={fund}
                status={status}
              />

            </div>

          </div>
        </div>
      </div>

    </section>
  )
}
