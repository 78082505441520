import React from 'react'
import PropertiesMap from './PropertiesMap/PropertiesMap'
import Styleguide from './Styleguide/Styleguide'
import Property from './Property/Property'
import PropertiesFeatured from './PropertiesFeatured/PropertiesFeatured'
import Platforms from './Platforms/Platforms'
import Media from './Media/Media'
import RelatedItems from './RelatedItems/RelatedItems'
import TitleDescription from './TitleDescription/TitleDescription'
import DataList from './DataList/DataList'
import DownloadLink from './DownloadLink/DownloadLink'
import ImageGallery from './ImageGallery/ImageGallery'
import Story from './Story/Story'
import GrayBackgroundFollows from './GrayBackgroundFollows/GrayBackgroundFollows'
import Hero from './Hero/Hero'
import HtmlSnippet from './HtmlSnippet/HtmlSnippet'

export const Components = {
  'dataList': DataList,
  'downloadLink': DownloadLink,
  'featuredProperties': PropertiesFeatured,
  'grayBackgroundFollows': GrayBackgroundFollows,
  'hero': Hero,
  'imageGallery': ImageGallery,
  'media': Media,
  'platforms': Platforms,
  'property': Property,
  'propertyMap': PropertiesMap,
  'relatedItems': RelatedItems,
  'story': Story,
  'styleguide': Styleguide,
  'titleDescription': TitleDescription,
  'htmlCode': HtmlSnippet,
}

const ComponentMapper = props => {
  const {data, user} = props
  if(!data) return null
  const {sys, fields} = data
  if(!sys || !fields) return null
  const id = sys?.id
  const contentType = sys?.contentType?.sys?.id
  // console.log('id', id)
  const Component = Components[contentType]
  if(!Component) return null
  return <Component id={id} data={fields} user={user}/>
}

export default ComponentMapper
