import React from 'react'
import styles from './TitleDescription.module.css'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'

export default function TitleDescription({id, data, className}) {
  const {title, description} = data

  return (
    <section id={id} className={`${styles.TitleDescription} ${className ? className : ''}`}>
      <div className="container">
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            {title &&
            <h1 className={styles.title}>{title}</h1>
            }
            {description &&
            <div className={styles.description}>
              {documentToReactComponents(description)}
            </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}
