import React from 'react'
import styles from './DescriptionList.module.css'
import DescriptionListItem from '../DescriptionListItem/DescriptionListItem'

export default function DescriptionList({data, showAsBulletList}) {
  const items = data?.fields?.items
  if(!items) return null
  // console.log('DescriptionList', items)

  if(showAsBulletList) {
    return (
      <ul className={styles.BulletList}>
        {items.map((item, idx) => <DescriptionListItem key={idx} data={item} showAsBulletList={showAsBulletList}/>)}
      </ul>
    )
  }

  return (
    <div className={styles.DescriptionList}>
      {items.map((item, idx) => <DescriptionListItem key={idx} data={item}/>)}
    </div>
  )
}
