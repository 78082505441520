
import React from 'react'
import styles from './DotNavigation.module.css'

export default function DotNavigation({numItems, index, setIndex, className}) {
  const dots = [...Array(numItems).keys()]

  return (
    <div className={`${styles.DotNavigation} ${className ? className : ''}`}>
      {dots.map(idx => {
        return (
          <div
            key={idx}
            className={index === idx ? styles.dotActive : styles.dot}
            onClick={() => setIndex && setIndex(idx)}
          />
        )
      })}
    </div>
  )
}
