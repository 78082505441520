const BASE_URL = '/api'
export const ENDPOINT_AUTHENTICATE_USER = `${BASE_URL}/authenticate`
export const ENDPOINT_REQUEST_PROPERTY_TOUR = `${BASE_URL}/request-property-tour`


const headersDefault = Object.freeze({
  'Content-Type': 'application/json'
})

export const getEndpoint = (ENDPOINT, payload = null, POST = false) => {
  const options = {
    method: POST ? 'POST' : 'GET',
    headers: {...{}, ...headersDefault},
    ...POST && payload && {body: JSON.stringify(payload)}
  }

  // console.log('payload', payload)

  const params = POST ? '' : payload

  return fetch(ENDPOINT + params, options)
    .then(res => res)
    .then(async res => {
      // console.log('res', res)
      if (res.status >= 400 && res.status < 600) {
        const json = await res.json()
        const {error, message} = json
        return {
          error: error,
          message: message,
        }
      }
      return res.json()
    })
    .catch(error => {
      console.log('getEndpoint ERROR', ENDPOINT, error)
      return {
        error: error,
        message: `getEndpoint ERROR: ${ENDPOINT}`
      }
    })
}
