import React from 'react'
import Image from '../Image/Image'
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer'
import styles from './Media.module.css'

export default function Media(props) {
  const {id, data} = props
  const {image, vimeoUrl} = data

  // console.log('Media', data)
  if(!image && !vimeoUrl) return null


  return (
    <section id={id} className={styles.Media}>
      {image &&
      <Image data={image} width={1920}/>
      }

      {vimeoUrl &&
      <VimeoPlayer vimeoUrl={vimeoUrl} background={false}/>
      }
    </section>
  )
}
