import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Page from '../pages/Page'
import SEO from './SEO/SEO'
import Login from './Login/Login'
import LoaderSpinner from './LoaderSpinner/LoaderSpinner'
import ModalOnBoarding from './ModalOnBoarding/ModalOnBoarding'

class Layout extends Component {
  render() {
    const {
      user,
      authenticating,
      authenticated,
      unauthorized,
      expired,
      showLoginScreen,
      showOnBoarding,
      closeOnBoarding,
      authenticateUser,
      page
    } = this.props

    const publicDomain = page?.publicDomain

    // console.log('authenticating', authenticating)
    // console.log('authenticated', authenticated)
    // console.log('page', page)

    const firstSection = page?.content ? page?.content[0] : null
    const firstSectionType = firstSection?.sys?.contentType?.sys?.id || null
    const firstIsHero = firstSectionType === 'hero'

    return (
      <>
        <SEO page={page}/>

        <Header {...this.props} firstIsHero={firstIsHero}/>

        <main>
          {!publicDomain && showLoginScreen &&
          <Login
            authenticating={authenticating}
            authenticated={authenticated}
            unauthorized={unauthorized}
            expired={expired}
            authenticateUser={authenticateUser}
            firstIsHero={firstIsHero}
          />
          }

          {showOnBoarding &&
          <ModalOnBoarding onClose={closeOnBoarding}/>
          }

          {(publicDomain || authenticated) &&
          <Switch>
            <Route
              path="/:slug?"
              render={props => <Page {...props} page={page} user={user}/>}
            />
          </Switch>
          }
        </main>

        {page &&
        <Footer authenticated={authenticated}/>
        }

        {!showLoginScreen && authenticating &&
        <LoaderSpinner/>
        }

      </>
    )
  }
}

export default Layout
