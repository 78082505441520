import React from 'react'
import {Helmet} from 'react-helmet'

const postfix = ' · NREP'

export default function SEO(props) {
  const {page} = props
  if(!page) return null

  const {
    title,
    description,
    image,
    slug,
  } = page

  return (
    <Helmet>
      {title &&
      <title>{title}{postfix}</title>
      }

      {description &&
      <meta name="description" content={description}/>
      }
      {/*<meta name="keywords" content=""/>*/}

      {title &&
      <meta property="og:title" content={`${title}${postfix}`}/>
      }
      <meta property="og:type" content="article"/>
      {slug &&
      <meta property="og:url" content={`https://propertytour.nrep.com${slug}`}/>
      }
      <meta property="og:site_name" content="Property Tour | NREP"/>
      {description &&
      <meta property="og:description" content={description}/>
      }
      {image &&
      <meta property="og:image" content={image}/>
      }
      <meta property="og:image:width" content="1200"/>


      <meta name="twitter:card" content="summary"/>
      {title &&
      <meta name="twitter:title" content={`${title}${postfix}`}/>
      }
      {description &&
      <meta name="twitter:description" content={description}/>
      }
      {image &&
      <meta name="twitter:image" content={image}/>
      }


      {slug &&
      <link rel="canonical" href={`https://propertytour.nrep.com${slug}`}/>
      }
    </Helmet>
  )
}
