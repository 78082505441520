import React from 'react'
import styles from './Platform.module.css'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import Image from '../Image/Image'
import {analyticsEvent} from '../../services/service-analytics'

export default function Platform({data}) {
  const {
    title,
    showPlatformOnProperty,
    description,
    logo,
    images,
    linkText,
    linkUrl,
  } = data.fields

  if(!showPlatformOnProperty) return null

  const hasImages = images && images.length

  return (
    <section className={hasImages ? styles.Platform : styles.PlatformNoImages}>
      {hasImages &&
      <div className={styles.imagesContainer}>
        <div className={styles.images}>
          {images &&
          images.map((image, idx) => {
            return (
              <div key={idx} className={styles.imageWrapper}>
                <Image
                  data={image}
                  width={1024}
                />
              </div>
            )
          })
          }
        </div>
      </div>
      }

      <div className={styles.content}>
        <div className="container">
          <div className="row center-xs">
            <div className="col-xs-12 col-sm-10 col-md-6 col-xl-4">
              <div className={styles.logoWrapper}>
                {logo &&
                <Image
                  data={logo}
                  alt={title}
                  className={styles.logo}
                  width={384}
                />
                }
                {!logo && title &&
                <h2>{title}</h2>
                }
              </div>

              {description &&
              documentToReactComponents(description)
              }

              {linkUrl && linkText &&
              <a
                className={`button ${styles.buttonLink}`}
                href={linkUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  analyticsEvent('platform', 'platform click cta', linkUrl)
                }}
              >
                {linkText}
              </a>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
