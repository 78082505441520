import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {fetchEntries} from '../services/service-contentful'
import {StateProvider} from '../StateContext'
import Layout from './Layout'
import {ENDPOINT_AUTHENTICATE_USER, getEndpoint} from '../services/service-api'
import {getCookie, setCookie} from '../services/service-cookies'
import {COOKIES} from '../constants/consts-cookies'
import {analyticsEvent} from '../services/service-analytics'
import {setLockedViewport} from '../services/service-utils'

class App extends Component {
  constructor(props) {
    super(props)

    this.authenticateUser = this.authenticateUser.bind(this)
    this.closeOnBoarding = this.closeOnBoarding.bind(this)

    this.state = {
      page: null,
      user: null,
      authenticating: true,
      authenticated: false,
      unauthorized: false,
      expired: false,
      showLoginScreen: false,
      showOnBoarding: false,
    }
  }

  componentDidMount() {
    this.loadPageData()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {showLoginScreen} = this.state

    const pathnamePrev = prevProps.location.pathname
    const {pathname} = this.props.location
    const isNewRoute = pathnamePrev !== pathname
    // console.log('isNewRoute', isNewRoute, pathnamePrev, pathname)

    if(isNewRoute) {
      this.setState({
        page: null
      })

      this.loadPageData()
    }

    const loginScreenDone = (prevState.showLoginScreen !== showLoginScreen) && !showLoginScreen
    if(loginScreenDone) {
      this.checkOnBoarding()
    }
  }

  checkOnBoarding() {
    const onboarded = getCookie(COOKIES.nrep_onboarded)
    if(!onboarded) {
      setTimeout(() => {
        this.setState({
          showOnBoarding: true
        })
      }, 500)
    }
  }

  closeOnBoarding = () => {
    setCookie(COOKIES.nrep_onboarded, true)
    setLockedViewport(false)
    this.setState({
      showOnBoarding: false
    })
  }

  async authenticateUser() {
    const encodedFromCookie = getCookie(COOKIES.nrep_pt_ue)

    if(!encodedFromCookie) {
      this.setState({
        authenticating: false,
        showLoginScreen: true,
      })
      return
    }

    const {search} = window.location
    const params = new URLSearchParams(search)
    const encodedFromUrl = params.get('ue')
    const encoded = encodedFromUrl || encodedFromCookie
    // console.log('encoded', encoded)

    setCookie(COOKIES.nrep_pt_ue, encoded)

    const email = window.atob(encoded)
    // console.log('email', email)

    const response = await getEndpoint(ENDPOINT_AUTHENTICATE_USER, `?email=${email.toLowerCase()}`, false)
    // console.log('authenticateUser', response)

    if(response) {
      if(response.error === 'Unauthorized') {
        this.setState({
          authenticating: false,
          authenticated: false,
          unauthorized: true,
          expired: false,
          showLoginScreen: true,
        })
        analyticsEvent('authentication', 'login', 'Unauthorized')

      } else if(response.error === 'Expired') {
        this.setState({
          authenticating: false,
          authenticated: false,
          unauthorized: false,
          expired: true,
          showLoginScreen: true,
        })
        analyticsEvent('authentication', 'login', 'Expired')

      } else if(response.authenticated && response.user) {
        const {user} = response
        this.setState({
          user: user,
          authenticating: false,
          authenticated: true,
          unauthorized: false,
          expired: false,
          showLoginScreen: false,
        })
        analyticsEvent('authentication', 'login', 'Success')
      } else {
        this.setState({
          authenticating: false,
          authenticated: false,
          unauthorized: false,
          expired: false,
          showLoginScreen: true,
        })
        analyticsEvent('authentication', 'login', 'Unhandled error')
      }
    } else {
      analyticsEvent('authentication', 'login', 'Service failed')
    }
  }

  async loadPageData() {
    const {pathname} = window.location
    // console.log('pathname', pathname)
    const slug = pathname === '/' ? pathname : pathname.substr(1).split('/')[0]
    // console.log('slug', slug)

    const res = await fetchEntries('page', slug)
    console.log('loadPageData', res)
    if(res && res.fields) {
      this.setState({
        page: res.fields
      }, () => {
        window.scrollTo(0, 0)
        this.authenticateUser()
      })
    }
  }

  render() {
    const {
      user,
      authenticating,
      authenticated,
      unauthorized,
      expired,
      showLoginScreen,
      showOnBoarding,
      page,
    } = this.state

    return (
      <StateProvider value={this.state}>
        <Layout
          {...this.props}
          user={user}
          authenticating={authenticating}
          authenticated={authenticated}
          unauthorized={unauthorized}
          expired={expired}
          showLoginScreen={showLoginScreen}
          showOnBoarding={showOnBoarding}
          closeOnBoarding={this.closeOnBoarding}
          authenticateUser={this.authenticateUser}
          page={page}
        />
      </StateProvider>
    )
  }
}

export default withRouter(App)
