import React from 'react'
import styles from './PropertyTags.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'

export default function PropertyTags({data, horizontal, className}) {
  if(!data) return null
  const {title, segment} = data
  // console.log('PropertyTags', data)

  return (
    <div className={`${horizontal ? styles.PropertyTagsHorizontal : styles.PropertyTags} ${className ? className : ''}`}>
      {title &&
      <div className={styles.platform}>
        <Svg id={ICONS.pin}/>
        <span>{title}</span>
      </div>
      }
      {segment &&
      <div className={styles.platform}>
        <Svg id={ICONS.house}/>
        <span>{segment.fields.title}</span>
      </div>
      }
    </div>
  )
}
