import React from 'react'
import styles from './DescriptionListItem.module.css'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'

export default function DescriptionListItem({data, showAsBulletList}) {
  if(!data) return null
  const {title, description} = data.fields

  if(showAsBulletList) {
    return (
      <li className={styles.ListItem}>
        {title &&
        <span className={styles.listItemTitle}>{title}</span>
        }
        {description &&
        <span>
          {documentToReactComponents(description)}
        </span>
        }
      </li>
    )
  }

  return (
    <dl className={styles.DescriptionListItem}>
      {title &&
      <dt>{title}</dt>
      }
      {description &&
      <dd>
        {documentToReactComponents(description)}
      </dd>
      }
    </dl>
  )
}
