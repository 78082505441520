export const getCookie = key => {
  const cookies = document.cookie
  if(!cookies) return null
  const cookieArray = cookies.split(';')
  const value = cookieArray
    .filter(cookie => {
      while(cookie.charAt(0) === ' ') cookie = cookie.substring(1)
      return cookie.split('=')[0] === key
    })
    .map(cookie => {
      while(cookie.charAt(0) === ' ') cookie = cookie.substring(1)
      return cookie.split('=')[1]
    })[0]

  if(value) {
    return decodeURIComponent(value)
  } else {
    return null
  }
}


export const setCookie = (key, value, exdays = 365) => {
  const cookieValueString = value ? encodeURIComponent(value) : ''
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = "expires=" + d.toUTCString()
  document.cookie = `${key}=${cookieValueString};${expires};path=/;SameSite=Lax`
}


export const deleteCookie = (key, exdays = 365) => {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = "expires=" + d.toUTCString()
  document.cookie = `${key}=;${expires};path=/;SameSite=Lax`
}

export const getCookieListDecoded = (key) => {
  const cookies = document.cookie
  if(!cookies) return []
  const cookieArray = cookies.split(';')
  const list = cookieArray
    .filter(cookie => {
      while(cookie.charAt(0) === ' ') cookie = cookie.substring(1)
      return cookie.split('=')[0] === key
    })
    .map(cookie => {
      while(cookie.charAt(0) === ' ') cookie = cookie.substring(1)
      return cookie.split('=')[1]
    })

  const listArray = list && list.length > 0 ? list[0].split(',') : []

  if(listArray && listArray.length > 0) {
    const listDecoded = listArray.filter(v => Boolean(v)).map(v => decodeURIComponent(v))
    return listDecoded
  } else {
    return []
  }
}

export const encodeAndSetCookieList = (key, list) => {
  const cookieValueString = list && list.length > 0 ? list.map(v => encodeURIComponent(v)).join(',') : ''
  const exdays = 1095
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = "expires=" + d.toUTCString()
  document.cookie = `${key}=${cookieValueString};${expires};path=/;SameSite=Lax`
}

export const addToCookieList = (key, value) => {
  const decodedList = getCookieListDecoded(key)
  const alreadyAdded = isOnCookieList(key, value)
  if(!alreadyAdded) {
    decodedList.push(value)
    encodeAndSetCookieList(key, decodedList)
  }
  return decodedList
}

export const isOnCookieList = (key, value) => {
  const list = getCookieListDecoded(key)
  return list.some(v => v === value)
}

export const removeFromCookieList = (key, value) => {
  const decodedList = getCookieListDecoded(key)
  const newList = decodedList.filter(v => v !== value)
  if(newList.length > 0) {
    encodeAndSetCookieList(key, newList)
  } else {
    deleteCookie(key)
  }
  return newList
}
