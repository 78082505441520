import React from 'react'
import {Link} from 'react-router-dom'
import styles from './Logo.module.css'
import Svg from '../Svg/Svg'
import ICONS from '../../constants/consts-icons'

export default function Logo() {
  return (
    <div className={styles.Logo}>
      <Link to="/" className={styles.linkLogo}>
        <Svg id={ICONS.nrep_logo}/>
        <div className={styles.titleWrapper}>
          <h1 className={`h5 ${styles.title}`}>VIRTUAL PROPERTY TOUR</h1>
        </div>
      </Link>
    </div>
  )
}
