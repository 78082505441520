import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import styles from './Map.module.css'
import {Loader} from '@googlemaps/js-api-loader'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import InfoWindow from './InfoWindow'
import {analyticsEvent} from '../../services/service-analytics'

const GoogleApiKey = 'AIzaSyAcUZoHogpqO1skkzSfflAclqxZkJ_I5tU';

class Map extends Component {
  constructor(props) {
    super(props)

    this.loadGoogleMapsApi = this.loadGoogleMapsApi.bind(this)
    this.setupInfoWindowListeners = this.setupInfoWindowListeners.bind(this)
    this.renderMap = this.renderMap.bind(this)
    this.onMarkerHover = this.onMarkerHover.bind(this)
    this.onMarkerClick = this.onMarkerClick.bind(this)

    this.google = null
    this.map = null
    this.markers = []
  }

  componentDidMount() {
    this.loadGoogleMapsApi()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {propertiesFiltered, toursRequested} = this.props

    if(propertiesFiltered && propertiesFiltered !== prevProps.propertiesFiltered) {
      this.renderMap()
    }

    if(toursRequested && toursRequested !== prevProps.toursRequested) {
      if(this.infoWindow) this.infoWindow.close()
    }
  }

  loadGoogleMapsApi() {
    const loader = new Loader({
      apiKey: GoogleApiKey,
      version: 'weekly',
      libraries: ['places']
    })

    loader
      .load()
      .then(google => {
        this.google = google
        this.renderMap()
      })
      .catch(error => {
        console.log('Map loader error', error)
      })
  }

  setupInfoWindowListeners() {
    const {onInfoCTA} = this.props
    if(!onInfoCTA) return
    const buttons = [...document.querySelectorAll('[data-property]')]
    if(buttons && buttons.length > 0) {
      buttons.forEach(button => {
        button.removeEventListener('click', onInfoCTA)
        button.addEventListener('click', onInfoCTA)
      })
    }
  }

  onMarkerHover(marker, property) {
    const {
      id,
    } = property
    new Image().src = `/assets/map/${id}.jpg`
  }

  onMarkerClick(marker, property) {
    const {
      onInfoCTA,
      toursRequested,
    } = this.props

    if(this.infoWindow) this.infoWindow.close()

    analyticsEvent('properties_map', 'map marker click', property.name)

    const content = ReactDOMServer.renderToString(
      <InfoWindow
        property={property}
        toursRequested={toursRequested}
      />
    )
    this.infoWindow.setContent(content)
    this.infoWindow.open({
      anchor: marker,
      map: this.map,
      shouldFocus: true,
    })

    if(onInfoCTA) setTimeout(this.setupInfoWindowListeners, 500)
  }

  renderMap() {
    const {
      propertiesFiltered,
      centerLatLng,
      zoom,
      enableInfo,
      enableFullscreen,
    } = this.props

    if(!this.google || !propertiesFiltered) {
      console.log('ERROR: No propertiesFiltered, or google maps not loaded')
      return
    }

    // clear markers if any:
    this.markers.forEach(marker => marker.setMap(null))

    const mapOptions = {
      center: centerLatLng,
      zoom: zoom || 5,
      fullscreenControl: enableFullscreen,
      mapTypeControlOptions: {
        mapTypeIds: []
      }
    }

    this.map = new this.google.maps.Map(this.mapNode, mapOptions)

    const markerTypes = {
      'Under development': '/assets/images/marker-blue-dark.svg',
      'Exited': '/assets/images/marker-blue-dark.svg',
      'In operation': '/assets/images/marker-blue-dark.svg',
      'tour': '/assets/images/marker-blue-dark-tour.svg'
    }

    this.infoWindow = new this.google.maps.InfoWindow({content: ''})

    this.markers = propertiesFiltered.map(p => {
      const {
        latitude,
        longitude,
        tour,
        status,
      } = p

      const position = new this.google.maps.LatLng(latitude, longitude)

      const marker = new this.google.maps.Marker({
        position: position,
        icon: tour && enableInfo ? markerTypes['tour'] : markerTypes[status],
        clickable: enableInfo,
        map: this.map,
      })

      if(enableInfo) {
        marker.addListener('mouseover', () => this.onMarkerHover(marker, p))
        marker.addListener('click', () => this.onMarkerClick(marker, p))
      }

      return marker
    })

    this.google.maps.event.addListener(this.map, 'click', () => {
      if(this.infoWindow) this.infoWindow.close()
    })

    const styleClusters = [
      {
        width: 36,
        height: 36,
      },
      {
        width: 40,
        height: 40,
      },
      {
        width: 50,
        height: 50,
      }
    ]

    this.clusters = new MarkerClusterer(this.map, this.markers, {
      styles: styleClusters,
      clusterClass: styles.clusterIcon
    })
  }

  render() {
    const {
      propertiesFiltered,
      className
    } = this.props

    if(!propertiesFiltered) return (
      <div className={styles.loader}>
        <LoaderSpinner/>
      </div>
    )

    return (
      <div
        ref={n => this.mapNode = n}
        className={`${styles.Map} ${className ? className : ''}`}
      />
    )
  }
}

export default withRouter(Map)
